import logo from './logo.svg';
import './App.css';
import greenlines from './imgs/greenlines2.png';
import discord_logo from './imgs/discord-logo.png';
import twitter_logo from './imgs/twitter-logo.png';
import threads_logo from './imgs/threads-logo.png';

function App() { // mobile size 912

  return (
    <div className="App">
      <div className="top-section">
        <div className="top-left-section">
          <p className="logo">δ</p>
          <p className="header-text">LIMSUP SOLUTIONS</p>
        </div>
      </div>

      <div className="main">
        <div className="info-div">
          <p className="about-text-header">About Us</p>
          <div className="about-main-div">
            <img style={{animation: `spin ${20}s linear infinite`}} src={greenlines} alt="greenlines" className="greenlines-img" />
            
            <div className="about-text-div">
              <p className="about-text"> Limsup Solutions is a cross-chain web3 service provider available on demand. We specialize in fast on-chain products for your web3 organization. We can reliably take care of your whole devstack or do single project deals. Eliminate the risk of developers rugging your projects with Limsup Solutions as your development provider. </p>
              <p className="socials-text">Social:</p>

              <div className="socials">
                <a className="socials-link" target="_blank" href="https://discord.gg/veecK8D9QA"><img className="socials-logo" src={discord_logo} /></a>
                <a className="socials-link" target="_blank" href="https://twitter.com/LimsupSolutions"><img className="socials-logo" src={twitter_logo} /></a>
                <a className="socials-link" target="_blank" href=""><img className="socials-logo" src={threads_logo} /></a>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-div">
          <p className="contact-text-header">Contact</p>

          <div className="contact-text-div">
            <p className="contact-about-text">To contact us for your project is simple. We are available through all the basic social media platforms and guarantee correspondence within 24 hours. We recommend all our clients to join our discord server to keep up with the latest news and to get the fastest responses. </p>
            <div className="contact-info-div">
              <p className="email-header"> Email: </p>
              <p> limsupsolutions@gmail.com </p>
              <a> </a>
            </div>
          </div>
        </div>

        <div className="projects-div">
          <p className="projects-text-header">Projects</p>

          <div className="contact-text-div">
            <p className="projects-about-text"> Coming soon... check our social media to keep updated on our latest, independently developed products and to get the chance to be an early adopter. </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
